import {
  FirebaseAppProvider,
  FirestoreProvider as FirestorePackageProvider,
  useFirebaseApp,
} from "reactfire";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDvwMlZFgeTmwB74cvBzTenB8rZRFQNsI0",
  authDomain: "wed-alcauskis.firebaseapp.com",
  projectId: "wed-alcauskis",
  storageBucket: "wed-alcauskis.appspot.com",
  messagingSenderId: "444782813166",
  appId: "1:444782813166:web:2c7e473eb84eb4a247f8ff",
  measurementId: "G-YMZEV1W7W0",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

const FirebaseProvider = ({ children }) => (
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <FirestoreProvider>{children}</FirestoreProvider>
  </FirebaseAppProvider>
);

const FirestoreProvider = ({ children }) => {
  const firestoreInstance = getFirestore(useFirebaseApp());

  return (
    <FirestorePackageProvider sdk={firestoreInstance}>
      {children}
    </FirestorePackageProvider>
  );
};

export default FirebaseProvider;
