import { collection, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import Card from "../components/Card";
import Heading from "../components/Heading";

const Guests = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();

  const firestore = useFirestore();
  const groupsCollection = collection(firestore, "groups");
  const groupQuery = query(groupsCollection, where("id", "==", groupId));

  const { status, data } = useFirestoreCollectionData(groupQuery);

  useEffect(() => {
    if (
      !groupId ||
      status === "error" ||
      (status === "success" && data.length === 0)
    )
      return navigate("/");
  }, [data, groupId, navigate, status]);

  if (status === "loading") return <p>Kraunama...</p>;

  return (
    <Card>
      <h1 className="text-4xl text-center font-bold">J + E</h1>
      <div className="divide-y divide-gray-200">
        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7 text-center flex flex-col gap-4">
          <section>
            <p>
              Ačiū, kad dalyvavote ir prisidėjote prie gražiausios mūsų gyvenimo
              dienos!&nbsp;✨
            </p>
          </section>
          <hr />
          <section>
            <p>Kaip ir žadėjome, dalinamės šventės akimirkomis 📸</p>
            <div className="my-6">
              <Heading>
                <a
                  href="https://kristinacheri.pixieset.com/justinairevaldas"
                  target="_blank"
                  rel="noreferrer"
                  className="text-emerald-900/80 hover:text-emerald-900"
                >
                  Nuoroda: Dienos nuotraukų albumas
                </a>
              </Heading>
            </div>
            <p>
              <small>
                Norint parsisiųsti nuotraukas reikia suvesti savo{" "}
                <b>el. paštą</b> ir PIN kodą <b>9029</b>
              </small>
            </p>
            <div className="my-6">
              <Heading>
                <a
                  href="https://drive.google.com/drive/folders/1I71KGe5VtRJImxSmhf9PpeXc9W67vRK4?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                  className="text-emerald-900/80 hover:text-emerald-900"
                >
                  Nuoroda: vakaro kadrai
                </a>
              </Heading>
            </div>
          </section>
          <hr />
          <section>
            <p>Taip pat čia galite rasti mūsų dienos filmus:</p>
            <div className="my-6">
              <Heading>
                <a
                  href="https://www.facebook.com/watch/?v=605407914545083"
                  target="_blank"
                  rel="noreferrer"
                  className="text-emerald-900/80 hover:text-emerald-900 font-bold italic"
                >
                  Nuoroda į trumpą video
                </a>
              </Heading>
            </div>
            <section className="border-dashed border-2 border-emerald-900 rounded-xl p-4 space-y-4">
              <p>Pilnas video:</p>
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/vnB_-__wJ24"
                title="Ilgas video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </section>
          </section>
        </div>
        <div className="pt-6 text-base text-center leading sm:text-lg sm:leading-7">
          <p>
            Jei klaustumėt, tai vedybinis gyvenimas toks pats geras, kaip ir
            ankstesnis.&nbsp;😉
          </p>
          <br />
          <p className="font-bold">🧑‍🚀👩‍🚀</p>
        </div>
      </div>
    </Card>
  );
};

export default Guests;
