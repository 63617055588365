import React from "react";

const Card = ({ children }) => (
  <div
    className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12"
    style={{ background: "url(/bg.jpeg)", backgroundSize: "cover" }}
  >
    <div className="relative py-3 sm:max-w-3xl sm:mx-auto">
      <div className="absolute inset-0 bg-gradient-to-r from-emerald-900 to-emerald-800 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 rounded-3xl"></div>
      <div className="relative px-4 py-10 bg-white shadow-lg rounded-3xl sm:p-20">
        {children}
      </div>
    </div>
  </div>
);

export default Card;
